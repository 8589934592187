import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import AntdModal from 'antd/lib/modal';

import { ModalProps } from './Modal.types';
import Button from '../Button';
import Image from '../Image';
import Popconfirm from '../Popconfirm';
import { getIconUrl } from '../../utils';

import './Modal.scss';

export const Modal: React.FC<ModalProps> = ({
  closable = false,
  visible,
  onCancel,
  className,
  wrapClassName,
  footerClassName,
  cancelButtonConfirm,
  cancelButtonConfirmClassName,
  cancelButtonConfirmText = 'Are you sure?',
  cancelButtonConfirmOkText = 'Yes',
  cancelButtonConfirmCancelText = 'No',
  cancelButtonProps,
  actionButtonsProps = [],
  ...rest
}) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);
  const [isModalClosable, setIsModalClosable] = useState(closable);
  const [isModalVisible, setIsModalVisible] = useState(visible);

  useEffect(() => {
    // If new closable prop value is passed in
    setIsModalClosable(closable);
  }, [closable]);

  useEffect(() => {
    // If new visible prop value is passed in
    setIsModalVisible(visible);
  }, [visible]);

  // Cancel button
  let cancelButton = !cancelButtonProps ? null : (
    <Button {...cancelButtonProps} />
  );

  if (cancelButtonProps && cancelButtonConfirm) {
    // Cancel confirmation
    const { onClick, ...restCancelButtonProps } = cancelButtonProps;
    cancelButton = (
      <Popconfirm
        data-testid="modal-popconfirm"
        className={cn('lex-modal__confirm', cancelButtonConfirmClassName)}
        visible={isConfirmVisible}
        icon={<Image src={getIconUrl('warning')} />}
        title={<span>{cancelButtonConfirmText}</span>}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ?? triggerNode
        }
        onConfirm={(e) => {
          setIsConfirmVisible(false);
          setTimeout(() => {
            onClick &&
              onClick(e as React.MouseEvent<HTMLButtonElement, MouseEvent>);
          }, 0);
        }}
        onCancel={() => {
          setIsConfirmVisible(false);
        }}
        okText={cancelButtonConfirmOkText}
        cancelText={cancelButtonConfirmCancelText}
      >
        <Button
          {...restCancelButtonProps}
          onClick={() => {
            setIsConfirmVisible(true);
          }}
        />
      </Popconfirm>
    );
  }

  // Action buttons
  const actionButtons = actionButtonsProps.map((actionButtonProps, idx) => {
    return <Button key={actionButtonProps.id || idx} {...actionButtonProps} />;
  });

  // Footer
  const footer =
    !cancelButton && !actionButtons.length ? null : (
      <div
        data-testid="modal-footer"
        className={cn('lex-modal__footer', footerClassName)}
      >
        {cancelButton}
        {actionButtons}
      </div>
    );

  // Close button
  let handleCancel;
  if (isModalClosable && cancelButtonProps && cancelButtonProps.onClick) {
    handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      cancelButtonProps &&
        cancelButtonProps.onClick &&
        cancelButtonProps.onClick(
          e as React.MouseEvent<HTMLButtonElement, MouseEvent>,
        );
    };
  }

  return (
    <AntdModal
      data-testid="modal"
      className={cn('lex-modal', className)}
      wrapClassName={cn('lex-modal__wrap', wrapClassName)}
      destroyOnClose
      centered
      closable={isModalClosable}
      visible={isModalVisible}
      onCancel={handleCancel || onCancel}
      footer={footer}
      {...rest}
    />
  );
};

export default Modal;
