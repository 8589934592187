import React from 'react';
import cn from 'classnames';

import { ButtonProps } from './Button.types';

import './Button.scss';

export const Button: React.FC<ButtonProps> = ({
  className,
  variant = 'primary',
  rounded = true,
  disabled,
  children,
  ...rest
}) => (
  <button
    data-testid="button"
    className={cn(
      'lex-button',
      variant && `lex-button--${variant}`,
      rounded && 'lex-button--rounded',
      disabled && 'lex-button--disabled',
      className,
    )}
    disabled={disabled}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
