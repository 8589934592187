import React, { useState } from 'react';
import cn from 'classnames';
import { useDeepCompareEffect } from 'react-use';
import AntCheckboxGroup from 'antd/lib/checkbox/Group';

import {
  CheckboxOptionType,
  CheckboxValueType,
  CheckboxGroupProps,
} from './CheckboxGroup.types';
import { Checkbox } from '../Checkbox';
import { withFormField } from '../../hocs/withFormField';

import './CheckboxGroup.scss';

export const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  id,
  className,
  checkboxClassName,
  variant = 'primary',
  disabled,
  block = false,
  options = [],
  value = undefined,
  defaultValue = [],
  onChange,
  hiddenLabel,
  error,
  ...rest
}) => {
  const values = value !== undefined ? value : defaultValue;

  const [checkedValues, setCheckedValues] =
    useState<CheckboxValueType[]>(values);

  useDeepCompareEffect(() => {
    setCheckedValues(values);
  }, [values]);

  const handleChange = (newCheckedValues: CheckboxValueType[]) => {
    setCheckedValues(newCheckedValues);
    onChange?.(newCheckedValues);
  };

  const checkboxes = options.map((option) => {
    const { value, label } = option as CheckboxOptionType;
    return (
      <div key={`${value}`}>
        <Checkbox
          id={`${id}-${value}`}
          data-testid="checkbox-group-checkbox"
          className={cn('lex-checkbox-group__checkbox', checkboxClassName)}
          value={value}
          variant={variant}
          disabled={disabled}
          error={error}
        >
          {label}
        </Checkbox>
      </div>
    );
  });

  return (
    <div id={id}>
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntCheckboxGroup
        data-testid="checkbox-group"
        className={cn(
          'lex-checkbox-group',
          block && 'lex-checkbox-group--block',
          error && 'lex-checkbox-group--error',
          disabled && 'lex-checkbox-group--disabled',
          className,
        )}
        value={checkedValues}
        disabled={disabled}
        onChange={handleChange}
        {...rest}
      >
        {checkboxes}
      </AntCheckboxGroup>
    </div>
  );
};

export default CheckboxGroup;

export const CheckboxGroupFormField = withFormField(CheckboxGroup);
