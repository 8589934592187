import React from 'react';
import cn from 'classnames';

import { TileProps } from './Tile.types';
import { Image } from '../Image';

import './Tile.scss';

export const Tile: React.FC<TileProps> = ({
  style,
  className,
  width,
  height,
  iconUrl,
  iconWidth,
  iconHeight,
  label,
}) => (
  <div
    data-testid="tile"
    className={cn('lex-tile', className)}
    style={{ width, height, ...style }}
  >
    {iconUrl && (
      <div className={cn('lex-tile__icon-container')}>
        <Image
          data-testid="tile-icon"
          className={cn('lex-tile__icon')}
          src={iconUrl}
          alt={label}
          width={iconWidth}
          height={iconHeight}
        />
      </div>
    )}
    <span data-testid="tile-label" className={cn('lex-tile__label')}>
      {label}
    </span>
  </div>
);

export default Tile;
