import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import cn from 'classnames';

import { Select } from '../Select';
import { CurrencyBox } from '../CurrencyBox';
import { withFormField } from '../../hocs/withFormField';
import { numberToCurrencyFormat, stringToCurrencyNumber } from '../../utils';
import {
  CurrencyPickerProps,
  CurrencyPickerData,
  ExchangeRateMap,
} from './CurrencyPicker.types';

import './CurrencyPicker.scss';

export const CurrencyPicker: React.FC<CurrencyPickerProps> = ({
  id,
  style,
  className,
  variant,
  options = [],
  onChange,
  onCurrencyCodeChange,
  onValueChange,
  onExchangeDataChange,
  value,
  maximumFractionDigits,
  useGrouping,
  locale = 'en-US',
  convertedValueDisplay,
  convertedValueNumber,
  defaultCurrencyCode = 'USD',
  currencyCode,
  exchangeCurrencyCode,
  exchangeRate,
  disabled,
  hiddenLabel,
  error,
  ...rest
}) => {
  currencyCode = currencyCode || defaultCurrencyCode;
  exchangeCurrencyCode = exchangeCurrencyCode || defaultCurrencyCode;
  exchangeRate = exchangeRate || 1;

  const inputRef = useRef<HTMLInputElement>(null);

  const allExchangeRates = useMemo<ExchangeRateMap>(
    () =>
      options.reduce((acc, option) => {
        if (option.value && option.exchange_rate) {
          acc[option.value] = option.exchange_rate as number;
        }
        return acc;
      }, {} as ExchangeRateMap),
    [options],
  );

  const getLatestPickerData = ({
    value,
    currencyCode,
    exchangeCurrencyCode,
  }: CurrencyPickerData) => {
    // Get new data, fallback to defaults
    const newCurrCode = currencyCode || defaultCurrencyCode;
    const newExchCurrCode = exchangeCurrencyCode || defaultCurrencyCode;
    const newExchRate = allExchangeRates[newCurrCode] || exchangeRate || 1;
    const strExchValue = getExchangeDisplayValue(value, newExchRate);

    return {
      value,
      currencyCode: newCurrCode,
      exchangeCurrencyCode: newExchCurrCode,
      exchangeRate: newExchRate,
      convertedValueDisplay: strExchValue,
      convertedValueNumber: stringToCurrencyNumber(strExchValue),
    } as CurrencyPickerData;
  };

  const getExchangeDisplayValue = (
    originValue?: number,
    exchangeRateOverride?: number,
  ) => {
    // This function converts the user-entered value into the tenant's base currency.
    // It should work when acceping exchangeRate as a prop, but also accepts an override
    // value for when the currency changes internally.

    const exchangeRateToUse = exchangeRateOverride || exchangeRate;
    if (exchangeRateToUse === undefined || originValue === undefined) {
      return '';
    }

    const strExchangeValue = numberToCurrencyFormat(
      (1 / exchangeRateToUse) * originValue,
      locale,
      exchangeCurrencyCode,
      maximumFractionDigits,
      useGrouping,
    );
    return strExchangeValue;
  };

  const [pickerData, setPickerData] = useState<CurrencyPickerData>(
    getLatestPickerData({
      currencyCode,
      value,
      exchangeCurrencyCode,
      exchangeRate,
      convertedValueDisplay,
      convertedValueNumber,
    }),
  );

  useDeepCompareEffect(() => {
    // Update picker data when exchange rates have changed
    const newPickerData = getLatestPickerData(pickerData);
    setPickerData(newPickerData);
  }, [allExchangeRates]);

  useEffect(() => {
    // Update picker data when new value prop is passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        value,
      }),
    );
  }, [value]);

  useEffect(() => {
    // Update picker data when new currencyCode prop is passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        currencyCode,
      }),
    );
  }, [currencyCode]);

  useEffect(() => {
    // Update picker data when new exchangeCurrencyCode prop is passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        exchangeCurrencyCode,
      }),
    );
  }, [exchangeCurrencyCode]);

  useEffect(() => {
    // Update picker data when new exchangeRate prop is passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        exchangeRate,
      }),
    );
  }, [exchangeRate]);

  useEffect(() => {
    // Update picker data when new convertedValueDisplay prop is passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        convertedValueDisplay,
      }),
    );
  }, [convertedValueDisplay]);

  useEffect(() => {
    // Update picker data when new convertedValueNumber prop is passed in
    setPickerData(
      getLatestPickerData({
        ...pickerData,
        convertedValueNumber,
      }),
    );
  }, [convertedValueNumber]);

  const {
    currencyCode: currentCurrencyCode,
    value: currentValue,
    exchangeCurrencyCode: currentExchangeCurrencyCode,
    exchangeRate: currentExchangeRate,
    convertedValueDisplay: currentConvertedValueDisplay,
    convertedValueNumber: currentConvertedValue,
  } = pickerData;

  const isDefaultCurrency =
    currentCurrencyCode?.toLowerCase() === defaultCurrencyCode.toLowerCase();

  const shouldShowConvertedValue =
    !isDefaultCurrency && !!currentConvertedValueDisplay;

  useEffect(() => {
    // Fire exchange data change event
    onExchangeDataChange?.({
      exchangeCurrencyCode: currentExchangeCurrencyCode,
      exchangeRate: currentExchangeRate,
      convertedValueDisplay: currentConvertedValueDisplay,
      convertedValueNumber: currentConvertedValue,
    });

    // Fire change event
    onChange?.(pickerData);
  }, [
    currentExchangeCurrencyCode,
    currentExchangeRate,
    currentConvertedValueDisplay,
    currentConvertedValue,
  ]);

  const handleCurrencyCodeChange = (currencyCode?: string) => {
    // Fire currency code change event
    const newPickerData = getLatestPickerData({
      ...pickerData,
      currencyCode,
    });

    setPickerData(newPickerData);
    onCurrencyCodeChange?.(currencyCode);

    // Fire change event
    onChange?.(newPickerData);
  };

  const handleValueChange = (value?: number) => {
    // Fire value change event
    const newPickerData = getLatestPickerData({
      ...pickerData,
      value,
    });

    setPickerData(newPickerData);
    onValueChange?.(value);

    // Fire change event
    onChange?.(newPickerData);
  };

  return (
    <div
      data-testid="currency-picker"
      className={cn('lex-currency-picker', className)}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <Select
        variant={variant}
        onChange={handleCurrencyCodeChange}
        options={options}
        value={currentCurrencyCode}
        disabled={disabled}
        error={error}
        allowClear={false}
      />
      <CurrencyBox
        ref={inputRef}
        variant={variant}
        onChange={handleValueChange}
        locale={locale}
        currencyCode={currentCurrencyCode}
        value={currentValue}
        maximumFractionDigits={maximumFractionDigits}
        useGrouping={useGrouping}
        disabled={disabled}
        error={error}
        {...rest}
      />
      {shouldShowConvertedValue && (
        <span
          data-testid="currency-picker-exchange-amount"
          className={cn('lex-currency-picker__exchange')}
        >
          =&nbsp;{currentConvertedValueDisplay}
        </span>
      )}
    </div>
  );
};

export default CurrencyPicker;

export const CurrencyPickerFormField = withFormField(CurrencyPicker);
