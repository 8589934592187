import React from 'react';
import cn from 'classnames';
import AntdTooltip from 'antd/lib/tooltip';

import { TooltipProps } from './Tooltip.types';

import './Tooltip.scss';

export const Tooltip: React.FC<TooltipProps> = ({ className, ...rest }) => {
  return (
    <AntdTooltip
      data-testid="tooltip"
      overlayClassName={cn('lex-tooltip', className)}
      destroyTooltipOnHide
      {...rest}
    />
  );
};

export default Tooltip;
