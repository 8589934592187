import React from 'react';
import cn from 'classnames';
import AntTag from 'antd/lib/tag';

import { Image } from '../Image';
import { TagProps } from './Tag.types';
import { getIconUrl } from '../../utils/assets';

import './Tag.scss';

export const Tag: React.FC<TagProps> = ({
  className,
  variant = 'primary',
  disabled,
  closable = true,
  children,
  ...rest
}) => {
  return (
    <AntTag
      data-testid="tag"
      className={cn(
        'lex-tag',
        variant && `lex-tag--${variant}`,
        disabled && 'lex-tag--disabled',
        className,
      )}
      closable={closable && !disabled}
      closeIcon={
        <Image
          className={cn('lex-tag__close-icon')}
          src={getIconUrl('clear')}
        />
      }
      {...rest}
    >
      {children}
    </AntTag>
  );
};

export default Tag;
