import React from 'react';
import cn from 'classnames';

import AntRadio from 'antd/lib/radio';
import { RadioProps } from './Radio.types';

import './Radio.scss';

export const Radio: React.FC<RadioProps> = ({
  id,
  className,
  variant = 'primary',
  disabled,
  error,
  ...rest
}) => {
  return (
    <AntRadio
      id={id}
      data-testid="radio"
      className={cn(
        'lex-radio',
        variant && `lex-radio--${variant}`,
        error && 'lex-radio--error',
        disabled && 'lex-radio--disabled',
        className,
      )}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Radio;
