import React from 'react';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/lib/date-picker/generatePicker';

import { DatePickerProps } from './DatePicker.types';
import Image from '../Image';
import { getIconUrl } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './DatePicker.scss';

const AntDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export const DatePicker: React.FC<DatePickerProps> = ({
  id,
  style,
  className,
  pickerClassName,
  variant,
  disabled,
  placeholder,
  format = 'MMM D, YYYY',
  defaultValue,
  value,
  hiddenLabel,
  error,
  onChange,
  ...rest
}) => {
  return (
    <div
      data-testid="date-picker"
      className={cn(
        'lex-date-picker',
        variant && `lex-date-picker--${variant}`,
        error && 'lex-date-picker--error',
        disabled && 'lex-date-picker--disabled',
        className,
      )}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntDatePicker
        id={id}
        data-testid="date-picker-input"
        className={cn('lex-date-picker__picker', pickerClassName)}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ?? triggerNode
        }
        disabled={disabled}
        placeholder={placeholder ?? ''}
        format={format}
        defaultValue={defaultValue ? dayjs(defaultValue) : undefined}
        value={value ? dayjs(value) : undefined}
        suffixIcon={
          <Image
            className={cn('lex-date-picker__picker-icon')}
            src={getIconUrl('calendar_today')}
          />
        }
        clearIcon={
          <Image
            className={cn('lex-date-picker__clear-icon')}
            src={getIconUrl('clear')}
          />
        }
        onChange={(value, dateStr) => {
          if (!value) {
            onChange?.(dateStr, value);
            return;
          }
          const isoValue = dayjs(value).toISOString().split('T')[0];
          onChange?.(isoValue, value);
        }}
        {...rest}
      />
    </div>
  );
};

export default DatePicker;

export const DatePickerFormField = withFormField(DatePicker);
