import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Select as AntdSelect } from 'antd';
import { useDebounce } from 'react-use';

import Button from '../Button';
import Image from '../Image';
import TextBox from '../TextBox';
import Tooltip from '../Tooltip';
import Pagination from '../Pagination';
import Spinner from '../Spinner';
import { SelectProps, Option } from './Select.types';
import { getIconUrl, escapeRegEx } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './Select.scss';

const classPrefix = 'lex-select';

export const Select: React.FC<SelectProps> = ({
  id,
  style,
  className,
  selectRef,
  selectClassName,
  variant,
  disabled,
  options,
  notFoundContent,
  onFocus,
  onChange,
  onBlur,
  onClear,
  onSearch,
  searchLoadingMessage = 'LOADING',
  clearIcon,
  defaultValue,
  value,
  searchValue,
  allowAdd,
  addPlaceholder = 'New value',
  addButtonLabel = 'Add',
  hiddenLabel,
  error,
  ...rest
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [addOptValue, setAddOptValue] = useState('');
  const [focused, setFocused] = useState(false);
  const [opts, setOpts] = useState(options || []);
  const [val, setVal] = useState(value);
  const [selectedOption, setSelectedOption] = useState<Option>();
  const [searchVal, setSearchVal] = useState(searchValue);
  const [debouncedSearchVal, setDebouncedSearchVal] = useState(searchValue);
  const [searchPer, setSearchPer] = useState<number | undefined>();
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);
  const [searchLoading, setSearchLoading] = useState(false);

  const enableAddOptions = !disabled && allowAdd;
  const enableSearchPagination = !disabled && !!searchPer;
  const renderCustomDropdown = enableAddOptions || enableSearchPagination;
  let addOptionsContainer: React.ReactNode;
  let searchPaginationContainer: React.ReactNode;
  let dropdownRender;

  const addOption = useCallback(
    (option: Option) => {
      setOpts([...opts, option]);
    },
    [opts],
  );

  const clearSearch = () => {
    setSearchPer(undefined);
    setSearchPage(1);
    setSearchTotal(0);
    setSearchLoading(false);
  };

  const performSearch = useCallback(() => {
    if (!onSearch) {
      return;
    }

    if (!debouncedSearchVal) {
      setOpts([]);
      clearSearch();
      return;
    }

    (async () => {
      try {
        setSearchLoading(true);

        const searchResult = await onSearch(debouncedSearchVal, {
          per: searchPer,
          page: searchPage,
        });

        if (Array.isArray(searchResult)) {
          // Result is an array of options
          setOpts(searchResult as Option[]);
        } else {
          // Result contains options as well as metadata
          const options = searchResult?.options || [];
          setOpts(options);

          setSearchPer(searchResult?.metadata?.pagination?.per);

          if (options.length) {
            setSearchTotal(searchResult?.metadata?.pagination?.totalCount || 0);
          } else {
            setSearchTotal(0);
            setSearchPage(1);
          }
        }
      } catch (err) {
        console.error(err);
        setOpts([]);
        clearSearch();
      } finally {
        setSearchLoading(false);

        setTimeout(() => {
          // Scroll to the top of the list
          const list = dropdownRef?.current?.querySelector(
            '.rc-virtual-list-holder',
          );
          if (list) {
            list.scrollTop = 0;
          }
        }, 0);
      }
    })();
  }, [onSearch, debouncedSearchVal, searchPage]);

  useDebounce(() => setDebouncedSearchVal(searchVal), 400, [searchVal]);

  useEffect(() => {
    // If new options prop value is passed in
    setOpts(options || []);
  }, [options]);

  useEffect(() => {
    // If new value prop value is passed in
    setVal(value);
  }, [value]);

  useEffect(() => {
    // If value does not exist in options
    const checkVal = val || defaultValue;

    if (!!checkVal && !opts.find((opt) => opt.value === checkVal)) {
      if (!!selectedOption && selectedOption.label && selectedOption.value) {
        addOption(selectedOption);
      } else {
        addOption({ label: checkVal, value: checkVal });
      }
    }
  }, [val, defaultValue]);

  useEffect(() => {
    // If new onSearch prop value is passed in
    clearSearch();
  }, [onSearch]);

  useEffect(() => {
    // Reset page if search value has changed
    setSearchPage(1);
  }, [debouncedSearchVal]);

  useEffect(() => {
    // Perform search if page OR search value has changed
    performSearch();
  }, [searchPage, debouncedSearchVal]);

  if (enableAddOptions) {
    const handleAddedOptionKeyDown = (
      e: React.KeyboardEvent<HTMLDivElement>,
    ) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
        handleAddedOptionClick();
      }
    };

    const handleAddedOptionChange = (
      e: React.ChangeEvent<HTMLInputElement>,
    ) => {
      setAddOptValue(e.target.value);
    };

    const handleAddedOptionClick = () => {
      const trimVal = addOptValue.trim();
      if (!trimVal || opts.find((opt) => opt.value === trimVal)) {
        return;
      }

      addOption({ label: trimVal, value: trimVal });
      setAddOptValue('');

      setTimeout(() => {
        // Scroll to the bottom of the list
        const list = dropdownRef?.current?.querySelector(
          '.rc-virtual-list-holder',
        );
        if (list) {
          list.scrollTop = list.scrollHeight;
        }
      }, 0);
    };

    addOptionsContainer = (
      <div className={cn(`${classPrefix}__dropdown-add-container`)}>
        <TextBox
          className={cn(`${classPrefix}__dropdown-add-input`)}
          data-testid="select-option-add-input"
          placeholder={addPlaceholder}
          value={addOptValue}
          onKeyDown={handleAddedOptionKeyDown}
          onChange={handleAddedOptionChange}
          variant={variant}
        />
        <Button
          className={cn(`${classPrefix}__dropdown-add-btn`)}
          data-testid="select-option-add-button"
          type="button"
          onClick={handleAddedOptionClick}
          variant={variant}
        >
          {addButtonLabel}
        </Button>
      </div>
    );
  }

  if (enableSearchPagination) {
    searchPaginationContainer = searchTotal > 0 && (
      <div className={cn(`${classPrefix}__dropdown-pagination-container`)}>
        <Pagination
          className={cn(`${classPrefix}__dropdown-pagination`)}
          data-testid="autocomplete-search-pagination"
          defaultPageSize={searchPer}
          current={searchPage}
          onChange={setSearchPage}
          total={searchTotal}
        />
      </div>
    );
  }

  if (renderCustomDropdown) {
    dropdownRender = function ddr(menu: unknown) {
      return (
        <div ref={dropdownRef} className={cn(`${classPrefix}__dropdown`)}>
          <Spinner
            size="small"
            spinning={searchLoading}
            className={cn(`${classPrefix}__dropdown-spinner`)}
          >
            {menu}
            {searchPaginationContainer}
            {addOptionsContainer}
          </Spinner>
        </div>
      );
    };
  }

  const showNoData = !!notFoundContent && !!val && !searchLoading;
  const noDataContent = showNoData ? notFoundContent : null;

  return (
    <div
      data-testid="select"
      className={cn(
        classPrefix,
        !notFoundContent && `${classPrefix}--hide-empty`,
        className,
      )}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <Spinner
        spinning={searchLoading}
        message={searchLoadingMessage}
        className={cn(`${classPrefix}__spinner`)}
      >
        <AntdSelect
          id={id}
          ref={selectRef}
          notFoundContent={noDataContent}
          virtual={!allowAdd}
          data-testid="select-select"
          getPopupContainer={(trigger) => {
            return trigger;
          }}
          allowClear
          clearIcon={
            clearIcon || (
              <Image
                className={cn(`${classPrefix}__clear`)}
                src={getIconUrl('clear')}
              />
            )
          }
          className={cn(
            `${classPrefix}__select`,
            variant && `${classPrefix}__select--${variant}`,
            error && `${classPrefix}__select--error`,
            disabled && `${classPrefix}__select--disabled`,
            focused && `${classPrefix}__select--focused`,
            selectClassName,
          )}
          disabled={disabled}
          showSearch
          showArrow={!onSearch}
          defaultActiveFirstOption={false}
          defaultValue={defaultValue}
          dropdownRender={dropdownRender}
          value={val}
          searchValue={searchVal}
          onFocus={(e) => {
            setFocused(true);
            onFocus?.(e);
          }}
          onChange={(value, option) => {
            setVal(value as string | undefined);
            setSelectedOption(option as Option | undefined);
            setSearchLoading(false);
            onChange?.(value, option);
          }}
          onBlur={(e) => {
            setFocused(false);
            setSearchLoading(false);
            onBlur?.(e);
          }}
          onSearch={
            !onSearch
              ? undefined
              : (value) => {
                  setSearchLoading(true);
                  setSearchVal(value);
                }
          }
          onClear={() => {
            if (onSearch) {
              setSearchVal('');
              setDebouncedSearchVal('');
              clearSearch();
            }
            onClear?.();
          }}
          optionLabelProp="label"
          filterOption={
            onSearch
              ? false
              : (value, option) => {
                  const label = option?.key?.toString()?.split('|')[0] ?? '';
                  const reg = new RegExp(`\\b${escapeRegEx(value)}`, 'i');
                  return !!reg.exec(label);
                }
          }
          {...rest}
        >
          {opts
            .sort((a, b) =>
              String(a.order).localeCompare(String(b.order), undefined, {
                numeric: true,
                sensitivity: 'base',
              }),
            )
            .map(({ label, value, disabled, tooltip, children, ...rest }) => (
              <AntdSelect.Option
                key={`${label}|${value}`}
                label={label}
                value={value}
                disabled={disabled}
                {...Object.keys(rest).reduce(
                  (acc, prop) => ({
                    ...acc,
                    [prop]:
                      toString.call(rest[prop]) === '[object Boolean]'
                        ? rest[prop]
                          ? 1
                          : 0
                        : rest[prop],
                  }),
                  {},
                )}
              >
                <Tooltip title={tooltip} placement="topLeft">
                  {children ?? label}
                </Tooltip>
              </AntdSelect.Option>
            ))}
        </AntdSelect>
      </Spinner>
    </div>
  );
};

export default Select;

export const SelectFormField = withFormField(Select);
