import React from 'react';
import cn from 'classnames';
import AntdPopover from 'antd/lib/popover';

import { PopoverProps } from './Popover.types';

import './Popover.scss';

export const Popover: React.FC<PopoverProps> = ({
  className,
  variant,
  ...rest
}) => {
  return (
    <AntdPopover
      data-testid="popover"
      overlayClassName={cn(
        'lex-popover',
        className,
        variant && `lex-popover--${variant}`,
      )}
      destroyTooltipOnHide
      {...rest}
    />
  );
};

export default Popover;
