import React, { useState } from 'react';
import cn from 'classnames';
import { Select as AntdSelect } from 'antd';

import { Image } from '../Image';
import { Tag } from '../Tag';
import Tooltip from '../Tooltip';
import { MultiselectProps } from './Multiselect.types';
import { getIconUrl, escapeRegEx } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './Multiselect.scss';

const classPrefix = 'lex-multiselect';

export const Multiselect: React.FC<MultiselectProps> = ({
  id,
  style,
  className,
  multiselectRef,
  multiselectClassName,
  tagClassName,
  variant,
  disabled,
  options = [],
  onFocus,
  onBlur,
  clearIcon,
  value,
  hiddenLabel,
  error,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div
      data-testid="multiselect"
      className={cn('lex-multiselect', className)}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntdSelect
        id={id}
        ref={multiselectRef}
        mode="multiple"
        notFoundContent={null}
        data-testid="multiselect-select"
        getPopupContainer={(trigger) => {
          return trigger;
        }}
        allowClear
        clearIcon={
          clearIcon || (
            <Image
              className={cn(`${classPrefix}__clear`)}
              src={getIconUrl('clear')}
            />
          )
        }
        className={cn(
          `${classPrefix}__select`,
          variant && `${classPrefix}__select--${variant}`,
          error && `${classPrefix}__select--error`,
          disabled && `${classPrefix}__select--disabled`,
          focused && `${classPrefix}__select--focused`,
          multiselectClassName,
        )}
        disabled={disabled}
        showSearch
        showArrow
        value={value}
        onFocus={(e) => {
          setFocused(true);
          onFocus?.(e);
        }}
        onBlur={(e) => {
          setFocused(false);
          onBlur?.(e);
        }}
        tagRender={({ label, value, ...tagProps }) => {
          return (
            <Tag
              {...tagProps}
              data-testid={`multiselect-tag-${value}`}
              className={cn('lex-multiselect__tag', tagClassName)}
              variant={variant}
              disabled={disabled}
            >
              {label}
            </Tag>
          );
        }}
        optionLabelProp="label"
        filterOption={(value, option) => {
          const label = option?.key?.toString()?.split('|')[0] ?? '';
          const reg = new RegExp(`\\b${escapeRegEx(value)}`, 'i');
          return !!reg.exec(label);
        }}
        {...rest}
      >
        {options
          .sort((a, b) =>
            String(a.order).localeCompare(String(b.order), undefined, {
              numeric: true,
              sensitivity: 'base',
            }),
          )
          .map(({ label, value, disabled, tooltip, children, ...rest }) => (
            <AntdSelect.Option
              key={`${label}|${value}`}
              label={label}
              value={value}
              disabled={disabled}
              {...Object.keys(rest).reduce(
                (acc, prop) => ({
                  ...acc,
                  [prop]:
                    toString.call(rest[prop]) === '[object Boolean]'
                      ? rest[prop]
                        ? 1
                        : 0
                      : rest[prop],
                }),
                {},
              )}
            >
              <Tooltip title={tooltip} placement="topLeft">
                {children ?? label}
              </Tooltip>
            </AntdSelect.Option>
          ))}
      </AntdSelect>
    </div>
  );
};

export default Multiselect;

export const MultiselectFormField = withFormField(Multiselect);
