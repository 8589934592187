import React, {
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
  Ref,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from 'react';
import cn from 'classnames';

import {
  getIconUrl,
  setNativeValue,
  numberToDecimalFormat,
  stringToDecimalNumber,
} from '../../utils';
import { NumericBoxProps } from './NumericBox.types';
import { withFormField } from '../../hocs/withFormField';
import { Image } from '../Image';

import './NumericBox.scss';

// eslint-disable-next-line react/display-name
export const NumericBox = forwardRef<HTMLInputElement, NumericBoxProps>(
  (
    {
      id,
      style,
      className,
      inputClassName,
      buttonClassName,
      variant,
      onFocus,
      onBlur,
      onChange,
      value,
      maximumFractionDigits,
      useGrouping,
      locale = 'en-US',
      disabled,
      hiddenLabel,
      error,
      ...rest
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [displayValue, setDisplayValue] = useState<string>(
      numberToDecimalFormat(value, locale, maximumFractionDigits, useGrouping),
    );

    useEffect(() => {
      setDisplayValue(
        numberToDecimalFormat(
          value,
          locale,
          maximumFractionDigits,
          useGrouping,
        ),
      );
    }, [value]);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      const numValue = stringToDecimalNumber(displayValue, locale);
      const newDisplayValue = String(numValue ?? '');
      setDisplayValue(newDisplayValue);
      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      const numValue = stringToDecimalNumber(displayValue, locale);
      const newDisplayValue = numberToDecimalFormat(
        numValue,
        locale,
        maximumFractionDigits,
        useGrouping,
      );
      setDisplayValue(newDisplayValue);
      onBlur?.(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const strValue = e.target.value;
      setDisplayValue(strValue);

      const numValue = stringToDecimalNumber(
        numberToDecimalFormat(
          stringToDecimalNumber(strValue, locale),
          locale,
          maximumFractionDigits,
          useGrouping,
        ),
        locale,
      );
      onChange?.(numValue);
    };

    const handleClearClick = () => {
      if (!inputRef.current) {
        return;
      }
      setNativeValue(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      setTimeout(() => inputRef.current?.focus(), 0);
    };

    const hasValue = displayValue !== '';

    return (
      <div
        data-testid="numericbox"
        className={cn('lex-numericbox', className)}
        style={style}
      >
        <div>
          {hiddenLabel && (
            <label className="screen-reader" htmlFor={id}>
              {hiddenLabel}
            </label>
          )}
          <input
            id={id}
            data-testid="numericbox-input"
            className={cn(
              'lex-numericbox__input',
              variant && `lex-numericbox__input--${variant}`,
              error && 'lex-numericbox__input--error',
              disabled && 'lex-numericbox__input--disabled',
              inputClassName,
            )}
            ref={(node) => {
              inputRef.current = node;
              if (typeof ref === 'function') {
                ref(node);
              } else if (ref) {
                (ref as MutableRefObject<HTMLDivElement | null>).current = node;
              }
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            value={displayValue}
            disabled={disabled}
            {...rest}
          />
          {!disabled && hasValue && (
            <button
              data-testid="numericbox-button"
              className={cn('lex-numericbox__clear', buttonClassName)}
              type="button"
              onClick={handleClearClick}
            >
              <Image src={getIconUrl('clear')} />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default NumericBox;

export const NumericBoxFormField = withFormField(NumericBox);
