import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';

import { TextAreaProps, TextAreaValue } from './TextArea.types';
import { Image } from '../Image';
import { getIconUrl, setNativeValue } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './TextArea.scss';

export const TextArea: React.FC<TextAreaProps> = ({
  id,
  style,
  className,
  textAreaClassName,
  buttonClassName,
  variant,
  onChange,
  value,
  rows = 6,
  disabled,
  hiddenLabel,
  error,
  ...rest
}) => {
  const [textAreaValue, setTextAreaValue] = useState<TextAreaValue>(value);
  const textArea = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setTextAreaValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
    onChange?.(e);
  };

  const handleClearClick = () => {
    if (!textArea.current) {
      return;
    }
    setNativeValue(textArea.current, '');
    textArea.current.dispatchEvent(new Event('input', { bubbles: true }));
    setTimeout(() => textArea.current?.focus(), 0);
  };

  return (
    <div
      data-testid="textarea"
      className={cn('lex-textarea', className)}
      style={style}
    >
      <div>
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
        <textarea
          id={id}
          data-testid="textarea-textarea"
          className={cn(
            'lex-textarea__textarea',
            variant && `lex-textarea__textarea--${variant}`,
            error && 'lex-textarea__textarea--error',
            disabled && 'lex-textarea__textarea--disabled',
            textAreaClassName,
          )}
          ref={textArea}
          onChange={handleChange}
          value={textAreaValue || ''}
          rows={rows}
          disabled={disabled}
          {...rest}
        />
        {!disabled && textAreaValue && (
          <button
            data-testid="textarea-button"
            className={cn('lex-textarea__clear', buttonClassName)}
            type="button"
            onClick={handleClearClick}
          >
            <Image src={getIconUrl('clear')} />
          </button>
        )}
      </div>
    </div>
  );
};

export default TextArea;

export const TextAreaFormField = withFormField(TextArea);
