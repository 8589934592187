import React from 'react';
import cn from 'classnames';
import SVG from 'react-inlinesvg';

import { ImageProps, HTMLImageProps, InlineSVGProps } from './Image.types';

import './Image.scss';

export const Image: React.FC<ImageProps> = ({ className, src, ...rest }) => {
  const isSvg = src?.split('?')[0].toLowerCase().endsWith('svg');

  const imgTag = (
    <img
      data-testid="image"
      className={cn('lex-image', className)}
      src={src}
      {...(rest as HTMLImageProps)}
    />
  );

  if (!src || !isSvg) {
    return imgTag;
  }

  return (
    <SVG
      className={className}
      src={src}
      uniquifyIDs
      {...(rest as InlineSVGProps)}
    >
      {imgTag}
    </SVG>
  );
};

export default Image;
