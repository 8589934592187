import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { AutoComplete as AntdAutoComplete } from 'antd';
import { useDebounce } from 'react-use';

import Image from '../Image';
import Tooltip from '../Tooltip';
import Pagination from '../Pagination';
import Spinner from '../Spinner';
import { AutoCompleteProps } from './AutoComplete.types';
import { Option } from '../Select/Select.types';
import { getIconUrl, escapeRegEx } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './AutoComplete.scss';

const classPrefix = 'lex-autocomplete';

export const AutoComplete: React.FC<AutoCompleteProps> = ({
  id,
  style,
  className,
  autoCompleteRef,
  autoCompleteClassName,
  variant,
  disabled,
  options,
  optionLabelProp = 'value',
  notFoundContent,
  onFocus,
  onChange,
  onBlur,
  onClear,
  onSearch,
  searchLoadingMessage = 'LOADING',
  clearIcon,
  defaultValue,
  value,
  searchValue,
  hiddenLabel,
  error,
  ...rest
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [focused, setFocused] = useState(false);
  const [opts, setOpts] = useState(options || []);
  const [val, setVal] = useState(value);
  const [searchVal, setSearchVal] = useState(searchValue);
  const [debouncedSearchVal, setDebouncedSearchVal] = useState(searchValue);
  const [searchPer, setSearchPer] = useState<number | undefined>();
  const [searchPage, setSearchPage] = useState<number>(1);
  const [searchTotal, setSearchTotal] = useState<number>(0);
  const [searchLoading, setSearchLoading] = useState(false);

  const enableSearchPagination = !disabled && !!searchPer;
  let dropdownRender;

  const clearSearch = () => {
    setSearchPer(undefined);
    setSearchPage(1);
    setSearchTotal(0);
    setSearchLoading(false);
  };

  const performSearch = useCallback(() => {
    if (!onSearch) {
      return;
    }

    if (!debouncedSearchVal) {
      setOpts([]);
      clearSearch();
      return;
    }

    (async () => {
      try {
        setSearchLoading(true);

        const searchResult = await onSearch(debouncedSearchVal, {
          per: searchPer,
          page: searchPage,
        });

        if (Array.isArray(searchResult)) {
          // Result is an array of options
          setOpts(searchResult as Option[]);
        } else {
          // Result contains options as well as metadata
          const options = searchResult?.options || [];
          setOpts(options);

          setSearchPer(searchResult?.metadata?.pagination?.per);

          if (options.length) {
            setSearchTotal(searchResult?.metadata?.pagination?.totalCount || 0);
          } else {
            setSearchTotal(0);
            setSearchPage(1);
          }
        }
      } catch (err) {
        console.error(err);
        setOpts([]);
        clearSearch();
      } finally {
        setSearchLoading(false);

        setTimeout(() => {
          // Scroll to the top of the list
          const list = dropdownRef?.current?.querySelector(
            '.rc-virtual-list-holder',
          );
          if (list) {
            list.scrollTop = 0;
          }
        }, 0);
      }
    })();
  }, [onSearch, debouncedSearchVal, searchPage]);

  useDebounce(() => setDebouncedSearchVal(searchVal), 400, [searchVal]);

  useEffect(() => {
    // If new options prop value is passed in
    setOpts(options || []);
  }, [options]);

  useEffect(() => {
    // If new value prop value is passed in
    setVal(value);
  }, [value]);

  useEffect(() => {
    // If new onSearch prop value is passed in
    clearSearch();
  }, [onSearch]);

  useEffect(() => {
    // Reset page if search value has changed
    setSearchPage(1);
  }, [debouncedSearchVal]);

  useEffect(() => {
    // Perform search if page OR search value has changed
    performSearch();
  }, [searchPage, debouncedSearchVal]);

  if (enableSearchPagination) {
    // Custom dropdown render for search pagination
    dropdownRender = function ddr(menu: unknown) {
      return (
        <div ref={dropdownRef} className={cn(`${classPrefix}__dropdown`)}>
          <Spinner
            size="small"
            spinning={searchLoading}
            className={cn(`${classPrefix}__dropdown-spinner`)}
          >
            {menu}
            {searchTotal > 0 && (
              <div
                className={cn(`${classPrefix}__dropdown-pagination-container`)}
              >
                <Pagination
                  className={cn(`${classPrefix}__dropdown-pagination`)}
                  data-testid="autocomplete-search-pagination"
                  defaultPageSize={searchPer}
                  current={searchPage}
                  onChange={setSearchPage}
                  total={searchTotal}
                />
              </div>
            )}
          </Spinner>
        </div>
      );
    };
  }

  const showNoData = !!notFoundContent && !!val && !searchLoading;
  const noDataContent = showNoData ? notFoundContent : null;

  return (
    <div
      data-testid="autocomplete"
      className={cn(
        classPrefix,
        !notFoundContent && `${classPrefix}--hide-empty`,
        className,
      )}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <Spinner
        spinning={searchLoading}
        message={searchLoadingMessage}
        className={cn(`${classPrefix}__spinner`)}
      >
        <AntdAutoComplete
          id={id}
          ref={autoCompleteRef}
          notFoundContent={noDataContent}
          data-testid="autocomplete-autocomplete"
          getPopupContainer={(trigger) => {
            return trigger;
          }}
          allowClear
          clearIcon={
            clearIcon || (
              <Image
                className={cn(`${classPrefix}__clear`)}
                src={getIconUrl('clear')}
              />
            )
          }
          className={cn(
            `${classPrefix}__autocomplete`,
            variant && `${classPrefix}__autocomplete--${variant}`,
            error && `${classPrefix}__autocomplete--error`,
            disabled && `${classPrefix}__autocomplete--disabled`,
            focused && `${classPrefix}__autocomplete--focused`,
            autoCompleteClassName,
          )}
          disabled={disabled}
          showSearch
          showArrow={!onSearch}
          defaultActiveFirstOption={false}
          defaultValue={defaultValue}
          dropdownRender={dropdownRender}
          value={val}
          searchValue={searchVal}
          onFocus={(e) => {
            setFocused(true);
            onFocus?.(e);
          }}
          onChange={(value, option) => {
            const optionValue =
              option && ((option as Option)[optionLabelProp] as string);
            const newVal = optionValue || value;
            setVal(newVal);
            setSearchLoading(false);
            onChange?.(newVal, option);
          }}
          onBlur={(e) => {
            setFocused(false);
            setSearchLoading(false);
            onBlur?.(e);
          }}
          onSearch={
            !onSearch
              ? undefined
              : (value) => {
                  setSearchLoading(true);
                  setSearchVal(value);
                }
          }
          onClear={() => {
            if (onSearch) {
              setSearchVal('');
              setDebouncedSearchVal('');
              clearSearch();
            }
            onClear?.();
          }}
          filterOption={
            onSearch
              ? false
              : (value, option) => {
                  const label = option?.key?.toString()?.split('|')[0] ?? '';
                  const reg = new RegExp(`\\b${escapeRegEx(value)}`, 'i');
                  return !!reg.exec(label);
                }
          }
          {...rest}
        >
          {opts
            .sort((a, b) =>
              String(a.order).localeCompare(String(b.order), undefined, {
                numeric: true,
                sensitivity: 'base',
              }),
            )
            .map(({ label, value, disabled, tooltip, children, ...rest }) => (
              <AntdAutoComplete.Option
                key={`${label}|${value}`}
                label={label}
                value={value}
                disabled={disabled}
                {...Object.keys(rest).reduce(
                  (acc, prop) => ({
                    ...acc,
                    [prop]:
                      toString.call(rest[prop]) === '[object Boolean]'
                        ? rest[prop]
                          ? 1
                          : 0
                        : rest[prop],
                  }),
                  {},
                )}
              >
                <Tooltip title={tooltip} placement="topLeft">
                  {children ?? label}
                </Tooltip>
              </AntdAutoComplete.Option>
            ))}
        </AntdAutoComplete>
      </Spinner>
    </div>
  );
};

export default AutoComplete;

export const AutoCompleteFormField = withFormField(AutoComplete);
