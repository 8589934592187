import React, { useState, useRef, useEffect } from 'react';
import cn from 'classnames';

import { TextBoxProps, TextBoxValue } from './TextBox.types';
import { Image } from '../Image';
import { getIconUrl, setNativeValue } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './TextBox.scss';

export const TextBox: React.FC<TextBoxProps> = ({
  id,
  style,
  className,
  inputClassName,
  buttonClassName,
  variant,
  onChange,
  value,
  disabled,
  hiddenLabel,
  error,
  ...rest
}) => {
  const [textBoxValue, setTextBoxValue] = useState<TextBoxValue>(value);
  const textInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTextBoxValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextBoxValue(e.target.value);
    onChange?.(e);
  };

  const handleClearClick = () => {
    if (!textInput.current) {
      return;
    }
    setNativeValue(textInput.current, '');
    textInput.current.dispatchEvent(new Event('input', { bubbles: true }));
    setTimeout(() => textInput.current?.focus(), 0);
  };

  return (
    <div
      data-testid="textbox"
      className={cn('lex-textbox', className)}
      style={style}
    >
      <div>
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
        <input
          id={id}
          data-testid="textbox-input"
          className={cn(
            'lex-textbox__input',
            variant && `lex-textbox__input--${variant}`,
            error && 'lex-textbox__input--error',
            disabled && 'lex-textbox__input--disabled',
            inputClassName,
          )}
          ref={textInput}
          onChange={handleChange}
          value={textBoxValue || ''}
          disabled={disabled}
          {...rest}
        />
        {!disabled && textBoxValue && (
          <button
            data-testid="textbox-button"
            className={cn('lex-textbox__clear', buttonClassName)}
            type="button"
            onClick={handleClearClick}
          >
            <Image src={getIconUrl('clear')} />
          </button>
        )}
      </div>
    </div>
  );
};

export default TextBox;

export const TextBoxFormField = withFormField(TextBox);
