import React, { useRef, useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Dropdown, Menu } from 'antd';

import Image from '../Image';
import Button from '../Button';
import Tooltip from '../Tooltip';
import { ButtonMenuProps } from './ButtonMenu.types';
import { getIconUrl } from '../../utils';

import './ButtonMenu.scss';

const defArrowIconSrc = getIconUrl('arrow_drop_down');

export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  style,
  menuStyle,
  className,
  menuClassName,
  dropdownButton,
  menuItems,
  arrowIconSrc,
  disabled,
  getPopupContainer,
}) => {
  if (!arrowIconSrc) arrowIconSrc = defArrowIconSrc;

  const ref = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const clickListener = useCallback(
    (e: MouseEvent) => {
      if (!ref.current?.contains(e.target as Node)) {
        setShowMenu(false);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickListener);
    return () => {
      document.removeEventListener('click', clickListener);
    };
  }, []);

  const {
    children,
    variant = 'primary',
    ...restDropdownButton
  } = dropdownButton;

  const overlay = (
    <Menu
      className={cn(
        'lex-button-menu__menu',
        variant && `lex-button-menu__menu--${variant}`,
        menuClassName,
      )}
      style={menuStyle}
      data-testid="button-menu-menu"
    >
      {menuItems.map((menuItem, idx) => {
        const { children, title, ...rest } = menuItem;
        return (
          <Menu.Item
            key={idx}
            data-testid="button-menu-menu-item"
            className={cn('lex-button-menu__menu-item')}
            {...rest}
          >
            <Tooltip title={title}>
              <span>{children}</span>
            </Tooltip>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <div
      data-testid="button-menu"
      className={cn(
        'lex-button-menu',
        disabled && `lex-button-menu--disabled`,
        className,
      )}
      style={style}
      ref={ref}
    >
      <Dropdown
        data-testid="button-menu-dropdown"
        className={cn('lex-button-menu__dropdown')}
        overlay={overlay}
        trigger={['click']}
        visible={showMenu}
        placement="bottomRight"
        disabled={disabled}
        getPopupContainer={getPopupContainer}
      >
        <Button
          data-testid="button-menu-dropdown-button"
          className={cn(
            'lex-button-menu__dropdown-button',
            variant && `lex-button-menu__dropdown-button--${variant}`,
            showMenu && 'active',
          )}
          type="button"
          variant={variant}
          onClick={() => setShowMenu(!showMenu)}
          {...restDropdownButton}
        >
          {children && (
            <span
              data-testid="button-menu-dropdown-button-span"
              className={cn('lex-button-menu__dropdown-button-span')}
            >
              {children}
            </span>
          )}
          <Image
            data-testid="button-menu-dropdown-button-image"
            className={cn('lex-button-menu__dropdown-button-image')}
            src={arrowIconSrc}
          />
        </Button>
      </Dropdown>
    </div>
  );
};

export default ButtonMenu;
