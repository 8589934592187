import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { PasswordBoxProps, PasswordBoxValue } from './PasswordBox.types';
import { withFormField } from '../../hocs/withFormField';

import './PasswordBox.scss';

export const PasswordBox: React.FC<PasswordBoxProps> = ({
  id,
  style,
  className,
  inputClassName,
  buttonClassName,
  variant,
  showPasswordLabel = 'Show',
  hidePasswordLabel = 'Hide',
  onChange,
  value = '',
  disabled,
  hiddenLabel,
  error,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordBoxValue, setPasswordBoxValue] =
    useState<PasswordBoxValue>(value);

  useEffect(() => {
    setPasswordBoxValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordBoxValue(e.target.value);
    onChange?.(e);
  };

  const handleToggleClick = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      data-testid="passwordbox"
      className={cn('lex-passwordbox', className)}
      style={style}
    >
      <div>
        {hiddenLabel && (
          <label className="screen-reader" htmlFor={id}>
            {hiddenLabel}
          </label>
        )}
        <input
          id={id}
          data-testid="passwordbox-input"
          className={cn(
            'lex-passwordbox__input',
            variant && `lex-passwordbox__input--${variant}`,
            error && 'lex-passwordbox__input--error',
            disabled && 'lex-passwordbox__input--disabled',
            inputClassName,
          )}
          onChange={handleChange}
          type={showPassword ? 'text' : 'password'}
          value={passwordBoxValue || ''}
          disabled={disabled}
          {...rest}
        />
        <button
          data-testid="passwordbox-button"
          className={cn('lex-passwordbox__toggle', buttonClassName)}
          type="button"
          onClick={handleToggleClick}
        >
          {showPassword ? hidePasswordLabel : showPasswordLabel}
        </button>
      </div>
    </div>
  );
};

export default PasswordBox;

export const PasswordBoxFormField = withFormField(PasswordBox);
