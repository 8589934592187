import { Breakpoint, BreakpointMax } from '../enums/media';

/**
 * Small devices (landscape phones, 576px and up)
 */
export const SM_AND_ABOVE = `(min-width: ${Breakpoint.SM})`;

/**
 * Medium devices (tablets, 768px and up)
 */
export const MD_AND_ABOVE = `(min-width: ${Breakpoint.MD})`;

/**
 * Large devices (desktops, 992px and up)
 */
export const LG_AND_ABOVE = `(min-width: ${Breakpoint.LG})`;

/**
 * X-Large devices (large desktops, 1200px and up)
 */
export const XL_AND_ABOVE = `(min-width: ${Breakpoint.XL})`;

/**
 * XX-Large devices (larger desktops, 1400px and up)
 */
export const XXL_AND_ABOVE = `(min-width: ${Breakpoint.XXL})`;

/**
 * X-Small devices (portrait phones, less than 576px)
 */
export const BELOW_SM = `(max-width: ${BreakpointMax.XS})`;

/**
 * Small devices (landscape phones, less than 768px)
 */
export const BELOW_MD = `(max-width: ${BreakpointMax.SM})`;

/**
 * Medium devices (tablets, less than 992px)
 */
export const BELOW_LG = `(max-width: ${BreakpointMax.MD})`;

/**
 * Large devices (desktops, less than 1200px)
 */
export const BELOW_XL = `(max-width: ${BreakpointMax.LG})`;

/**
 * X-Large devices (large desktops, less than 1400px)
 */
export const BELOW_XXL = `(max-width: ${BreakpointMax.XL})`;

export const XS_ONLY = BELOW_SM;
export const SM_ONLY = `${SM_AND_ABOVE} and ${BELOW_MD}`;
export const MD_ONLY = `${MD_AND_ABOVE} and ${BELOW_LG}`;
export const LG_ONLY = `${LG_AND_ABOVE} and ${BELOW_XL}`;
export const XL_ONLY = `${XL_AND_ABOVE} and ${BELOW_XXL}`;
export const XXL_ONLY = XXL_AND_ABOVE;

export const LANDSCAPE = '(orientation: landscape)';
export const PORTRAIT = '(orientation: portrait)';

export default {
  SM_AND_ABOVE,
  MD_AND_ABOVE,
  LG_AND_ABOVE,
  XL_AND_ABOVE,
  XXL_AND_ABOVE,
  BELOW_SM,
  BELOW_MD,
  BELOW_LG,
  BELOW_XL,
  BELOW_XXL,
  XS_ONLY,
  SM_ONLY,
  MD_ONLY,
  LG_ONLY,
  XL_ONLY,
  XXL_ONLY,
  LANDSCAPE,
  PORTRAIT,
};
