import React, { useState } from 'react';
import cn from 'classnames';
import TruncateMarkup from 'react-truncate-markup';
import parse from 'html-react-parser';

import { TruncateProps } from './Truncate.types';

import './Truncate.scss';

export const Truncate: React.FC<TruncateProps> = ({
  className,
  style,
  htmlContent,
  showMore,
  showMoreText = 'More',
  showLessText = 'Less',
  children,
  ...rest
}) => {
  const [shouldTruncate, setShouldTruncate] = useState(showMore);

  const content = htmlContent ? <span>{parse(htmlContent)}</span> : children;

  if (!showMore) {
    return (
      <div
        data-testid="truncate"
        className={cn('lex-truncate', className)}
        style={style}
      >
        <TruncateMarkup {...rest}>{content}</TruncateMarkup>
      </div>
    );
  }

  const toggleTruncate = () => {
    setShouldTruncate(!shouldTruncate);
  };

  const showMoreLink = (
    <span>
      ...{' '}
      <span
        data-testid="truncate-more"
        className={cn('lex-truncate__link')}
        onClick={toggleTruncate}
      >
        {showMoreText}
      </span>
    </span>
  );

  if (shouldTruncate) {
    return (
      <div
        data-testid="truncate"
        className={cn('lex-truncate', className)}
        style={style}
      >
        <TruncateMarkup ellipsis={showMoreLink} {...rest}>
          {content}
        </TruncateMarkup>
      </div>
    );
  }

  const showLessLink = (
    <span>
      {' '}
      <span
        data-testid="truncate-less"
        className={cn('lex-truncate__link')}
        onClick={toggleTruncate}
      >
        {showLessText}
      </span>
    </span>
  );

  return (
    <div
      data-testid="truncate"
      className={cn('lex-truncate', className)}
      style={style}
    >
      <div>
        {content}
        {showLessLink}
      </div>
    </div>
  );
};

export default Truncate;
