import React, { useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Image from '../Image';
import { StepperProps, StepStatus } from './Stepper.types';
import { getIconUrl } from '../../utils';

import './Stepper.scss';

const defErrorIconSrc = getIconUrl('warning');
const defApprovedIconSrc = getIconUrl('check');
const defRejectedIconSrc = getIconUrl('close');
const defReturnedIconSrc = getIconUrl('keyboard_return');
const defRecordedIconSrc = getIconUrl('check_circle');
const defSkippedIconSrc = getIconUrl('shortcut');

const getSafeNumber = (num: number | undefined) =>
  num !== undefined ? num : 0;

export const Stepper: React.FC<StepperProps> = ({
  id,
  style,
  stepStyle,
  stepCircleStyle,
  stepTitleStyle,
  stepSubtitleStyle,
  className,
  stepClassName,
  stepCircleClassName,
  stepTitleClassName,
  stepSubtitleClassName,
  variant = 'primary',
  orientation = 'vertical',
  steps = [],
  activeStepId,
  connectorSize = 2,
  iconSize = 'small',
  errorIconSrc,
  approvedIconSrc,
  rejectedIconSrc,
  returnedIconSrc,
  recordedIconSrc,
  skippedIconSrc,
  notStartedIconTitle,
  inProgressIconTitle,
  errorIconTitle,
  approvedIconTitle,
  rejectedIconTitle,
  returnedIconTitle,
  recordedIconTitle,
  skippedIconTitle,
}) => {
  if (!errorIconSrc) errorIconSrc = defErrorIconSrc;
  if (!approvedIconSrc) approvedIconSrc = defApprovedIconSrc;
  if (!rejectedIconSrc) rejectedIconSrc = defRejectedIconSrc;
  if (!returnedIconSrc) returnedIconSrc = defReturnedIconSrc;
  if (!recordedIconSrc) recordedIconSrc = defRecordedIconSrc;
  if (!skippedIconSrc) skippedIconSrc = defSkippedIconSrc;

  const firstStepRef = useRef<HTMLDivElement>(null);
  const lastStepRef = useRef<HTMLDivElement>(null);

  const [firstStepRect, setFirstStepRect] = useState<DOMRect | undefined>();
  const [lastStepRect, setLastStepRect] = useState<DOMRect | undefined>();

  const hasMultipleSteps = steps.length > 1;

  useLayoutEffect(() => {
    const setRects = () => {
      setFirstStepRect(firstStepRef?.current?.getBoundingClientRect());
      setLastStepRect(lastStepRef?.current?.getBoundingClientRect());
    };
    setRects();
    const intervalId = setInterval(setRects, 1000);
    return () => clearInterval(intervalId);
  }, [steps, orientation]);

  const getStepIconTitle = (status?: StepStatus) => {
    switch (status) {
      case StepStatus.IN_PROGRESS:
        return inProgressIconTitle;
      case StepStatus.ERROR:
        return errorIconTitle;
      case StepStatus.APPROVED:
        return approvedIconTitle;
      case StepStatus.REJECTED:
        return rejectedIconTitle;
      case StepStatus.RETURNED:
        return returnedIconTitle;
      case StepStatus.RECORDED:
        return recordedIconTitle;
      case StepStatus.SKIPPED:
        return skippedIconTitle;
      default:
        return notStartedIconTitle;
    }
  };

  const getStepIconUrl = (status?: StepStatus) => {
    switch (status) {
      case StepStatus.ERROR:
        return errorIconSrc;
      case StepStatus.APPROVED:
        return approvedIconSrc;
      case StepStatus.REJECTED:
        return rejectedIconSrc;
      case StepStatus.RETURNED:
        return returnedIconSrc;
      case StepStatus.RECORDED:
        return recordedIconSrc;
      case StepStatus.SKIPPED:
        return skippedIconSrc;
      default:
        return;
    }
  };

  const renderConnector = () => {
    if (!firstStepRect || !lastStepRect) {
      return null;
    }

    let top, left, width, height;

    if (orientation === 'vertical') {
      top = getSafeNumber(firstStepRect?.height) / 2;
      left = getSafeNumber(firstStepRect?.width) / 2 - connectorSize / 2;
      width = connectorSize;
      height =
        getSafeNumber(lastStepRect?.top) - getSafeNumber(firstStepRect?.top);
    } else {
      top = getSafeNumber(firstStepRect?.height) / 2;
      left = getSafeNumber(firstStepRect?.width) / 2 - connectorSize / 2;
      width =
        getSafeNumber(lastStepRect?.left) - getSafeNumber(firstStepRect?.left);
      height = connectorSize;
    }

    return (
      <div
        className={cn('lex-stepper__step-circle-connector')}
        style={{
          top,
          left,
          width,
          height,
        }}
      ></div>
    );
  };

  const stepDivs = steps.map((step, idx) => {
    const { id, title, subtitle, status = StepStatus.NOT_STARTED } = step;
    const stepIconTitle = getStepIconTitle(status);
    const stepIconUrl = getStepIconUrl(status);

    const isFirstStep = idx === 0;
    const isLastStep = idx === steps.length - 1;
    const showConnector = hasMultipleSteps && isFirstStep;

    return (
      <div
        key={id}
        data-testid="stepper-step"
        className={cn(
          'lex-stepper__step',
          stepClassName,
          id === activeStepId && 'lex-stepper__step--active',
          status && `lex-stepper__step--${status}`,
        )}
        style={stepStyle}
      >
        <div
          className={cn(
            'lex-stepper__step-circle',

            stepCircleClassName,
          )}
          style={stepCircleStyle}
          ref={
            isFirstStep ? firstStepRef : isLastStep ? lastStepRef : undefined
          }
        >
          <div
            className={cn(
              'lex-stepper__step-circle-icon',
              `lex-stepper__step-circle-icon--${iconSize}`,
            )}
            title={stepIconTitle}
          >
            <Image src={stepIconUrl} />
          </div>
          {showConnector && renderConnector()}
        </div>
        <div className={cn('lex-stepper__step-title-container')}>
          <div
            className={cn('lex-stepper__step-title', stepTitleClassName)}
            style={stepTitleStyle}
          >
            {title}
          </div>
          {subtitle && (
            <div
              className={cn(
                'lex-stepper__step-subtitle',
                stepSubtitleClassName,
              )}
              style={stepSubtitleStyle}
            >
              {subtitle}
            </div>
          )}
        </div>
      </div>
    );
  });

  return (
    <div
      id={id}
      data-testid="stepper"
      className={cn(
        'lex-stepper',
        `lex-stepper--${variant}`,
        `lex-stepper--${orientation}`,
        className,
      )}
      style={style}
    >
      <div className={cn('lex-stepper__steps')}>{stepDivs}</div>
    </div>
  );
};

export default Stepper;
