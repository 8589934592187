import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import AntPagination from 'antd/lib/pagination';

import { PaginationProps } from './Pagination.types';
import { Image } from '../Image';
import { getIconUrl } from '../../utils';

import './Pagination.scss';

const classPrefix = 'lex-pagination';
const defPrevIconSrc = getIconUrl('chevron_left');
const defNextIconSrc = getIconUrl('chevron_right');
const defSize = 10;
const defPage = 1;

export const Pagination: React.FC<PaginationProps> = ({
  className,
  totalClassName,
  previousButtonClassName,
  nextButtonClassName,
  totalLabel = '{pageBegin}-{pageEnd} of {total}',
  previousLabel = 'Previous',
  previousIconSrc,
  nextLabel = 'Next',
  nextIconSrc,
  pageSize,
  defaultPageSize = defSize,
  current,
  defaultCurrent = defPage,
  total,
  onChange,
  ...rest
}) => {
  if (!previousIconSrc) previousIconSrc = defPrevIconSrc;
  if (!nextIconSrc) nextIconSrc = defNextIconSrc;

  const [size, setSize] = useState(pageSize || defaultPageSize);
  const [page, setPage] = useState(current || defaultCurrent);

  useEffect(() => {
    // If new pageSize prop value is passed in
    setSize(pageSize || defaultPageSize);
  }, [pageSize]);

  useEffect(() => {
    // If new current prop value is passed in
    setPage(current || defaultCurrent);
  }, [current]);

  useEffect(() => {
    // Reset page if total is less than current page based on size
    if (page && size && total && (page - 1) * size > total) {
      setPage(1);
    }
  });

  if (!total) {
    return null;
  }

  const renderTotalSpan = (total: number, range: [number, number]) => {
    const totalSpan = totalLabel
      .replace('{pageBegin}', range[0].toString())
      .replace('{pageEnd}', range[1].toString())
      .replace('{total}', total.toString());

    return (
      <span
        data-testid="pagination-total"
        className={cn(`${classPrefix}__total`, totalClassName)}
      >
        {totalSpan}
      </span>
    );
  };

  return (
    <AntPagination
      data-testid="pagination"
      className={cn(
        classPrefix,
        total <= size && `${classPrefix}--no-buttons`,
        className,
      )}
      pageSize={size}
      current={page}
      total={total}
      showLessItems
      showQuickJumper={false}
      showSizeChanger={false}
      showTitle={false}
      showTotal={renderTotalSpan}
      onChange={(page: number, pageSize = defaultPageSize) => {
        setSize(pageSize);
        setPage(page);
        onChange?.(page, pageSize);
      }}
      itemRender={(_page, type, originalElement) => {
        if (type === 'prev') {
          return (
            <div
              role="button"
              data-testid="pagination-previous"
              className={cn(
                `${classPrefix}__previous`,
                previousButtonClassName,
              )}
              tabIndex={0}
              title={previousLabel}
            >
              <Image
                className={cn(
                  `${classPrefix}__previous`,
                  previousButtonClassName,
                )}
                src={previousIconSrc}
              />
            </div>
          );
        }
        if (type === 'next') {
          return (
            <div
              role="button"
              data-testid="pagination-next"
              className={cn(`${classPrefix}__next`, nextButtonClassName)}
              tabIndex={0}
              title={nextLabel}
            >
              <Image
                className={cn(`${classPrefix}__next`, nextButtonClassName)}
                src={nextIconSrc}
              />
            </div>
          );
        }
        return originalElement;
      }}
      {...rest}
    />
  );
};

export default Pagination;
