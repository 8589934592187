import React, { useState } from 'react';
import cn from 'classnames';

import { WithFormFieldProps } from './withFormField.types';
import { Image } from '../../components/Image';
import { Modal } from '../../components/Modal';
import { getIconUrl } from '../../utils';

import './withFormField.scss';

const classPrefix = 'lex-with-form-field';
const defInfoIconSrc = getIconUrl('info');
const defErrorIconSrc = getIconUrl('warning');

export const withFormField = <P extends unknown>(
  Component: React.ComponentType<P>,
): React.FC<P & WithFormFieldProps> => {
  // eslint-disable-next-line react/display-name
  return ({
    formFieldStyle,
    formFieldClassName,
    label,
    labelStyle,
    labelClassName,
    infoIconSrc,
    infoIconTitle = 'More Info',
    infoModalStyle,
    infoModalClassName,
    infoText,
    infoTextStyle,
    infoTextClassName,
    errorIconSrc,
    errorText,
    errorStyle,
    errorClassName,
    ...rest
  }: WithFormFieldProps) => {
    if (!infoIconSrc) infoIconSrc = defInfoIconSrc;
    if (!errorIconSrc) errorIconSrc = defErrorIconSrc;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const formLabel = label && (
      <div
        className={cn(`${classPrefix}__label-container`, labelClassName)}
        style={labelStyle}
      >
        <span
          data-testid="with-form-field-label"
          className={cn(`${classPrefix}__label`)}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {infoText && (
          <>
            <div
              data-testid="with-form-field-icon"
              role="button"
              tabIndex={0}
              className={cn(`${classPrefix}__icon-container`)}
              onClick={() => setIsModalVisible(true)}
            >
              <Image
                className={cn(`${classPrefix}__icon`)}
                src={infoIconSrc}
                title={infoIconTitle}
              />
            </div>
            <Modal
              closable
              className={cn(`${classPrefix}__info-modal`, infoModalClassName)}
              style={infoModalStyle}
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
            >
              <span
                data-testid="with-form-field-info-text"
                className={cn(`${classPrefix}__info-text`, infoTextClassName)}
                style={infoTextStyle}
                dangerouslySetInnerHTML={{
                  __html: infoText,
                }}
              />
            </Modal>
          </>
        )}
      </div>
    );

    const hasError = !!errorText;

    const errorLabel = hasError && (
      <div
        className={cn(`${classPrefix}__error-container`, errorClassName)}
        style={errorStyle}
      >
        <Image
          className={cn(`${classPrefix}__error-icon`)}
          src={errorIconSrc}
        />
        <span
          data-testid="with-form-field-error"
          className={cn(`${classPrefix}__error-text`)}
          dangerouslySetInnerHTML={{
            __html: errorText,
          }}
        />
      </div>
    );

    return (
      <div
        data-testid="with-form-field"
        className={cn(classPrefix, formFieldClassName)}
        style={formFieldStyle}
      >
        {formLabel}
        <div className={cn(`${classPrefix}__component-container`)}>
          <Component {...(rest as P)} hiddenLabel={label} error={hasError} />
          {errorLabel}
        </div>
      </div>
    );
  };
};

export default withFormField;
