import React, { ReactElement } from 'react';
import cn from 'classnames';
import AntdCollapse from 'antd/lib/collapse';
import { getIconUrl } from '../../utils';
import { Image } from '../Image';
import { CollapseProps, CollapsePanelProps } from './Collapse.types';

import './Collapse.scss';

export const CollapsePanel: React.FC<CollapsePanelProps> = ({
  className,
  children,
  key,
  ...rest
}) => (
  <AntdCollapse.Panel
    data-testid={`lex-collapse-panel-${key}`}
    className={cn('lex-collapse__panel', className)}
    key={key}
    {...rest}
  >
    {children}
  </AntdCollapse.Panel>
);

export const Collapse: React.FC<CollapseProps> = ({
  className,
  panelClassName,
  expandIconClassName,
  expandIconImageSrc = getIconUrl('expand_more'),
  collapseIconImageSrc = getIconUrl('expand_less'),
  expandIcon,
  panels = [],
  ...rest
}) => {
  const collapsePanels: ReactElement<CollapsePanelProps>[] = panels.map(
    (panel) => (
      <CollapsePanel
        className={panelClassName}
        key={panel.key}
        header={panel.header}
      >
        {panel.children}
      </CollapsePanel>
    ),
  );

  const defaultExpandIcon: CollapseProps['expandIcon'] = ({ isActive }) => (
    <Image
      className={cn(`lex-collapse__expand-icon`, expandIconClassName)}
      src={isActive ? collapseIconImageSrc : expandIconImageSrc}
    />
  );

  return (
    <AntdCollapse
      data-testid="lex-collapse"
      className={cn('lex-collapse', className)}
      expandIcon={expandIcon ?? defaultExpandIcon}
      {...rest}
    >
      {collapsePanels}
    </AntdCollapse>
  );
};

export default Collapse;
