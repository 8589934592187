import React, {
  ChangeEvent,
  FocusEvent,
  Ref,
  MutableRefObject,
  forwardRef,
  useEffect,
  useState,
  useRef,
} from 'react';
import cn from 'classnames';

import {
  getIconUrl,
  setNativeValue,
  numberToCurrencyFormat,
  stringToCurrencyNumber,
} from '../../utils';
import { CurrencyBoxProps } from './CurrencyBox.types';
import { withFormField } from '../../hocs/withFormField';
import { Image } from '../Image';

import './CurrencyBox.scss';

// eslint-disable-next-line react/display-name
export const CurrencyBox = forwardRef<HTMLInputElement, CurrencyBoxProps>(
  (
    {
      id,
      style,
      className,
      inputClassName,
      buttonClassName,
      variant,
      onFocus,
      onBlur,
      onChange,
      value,
      maximumFractionDigits,
      useGrouping,
      locale = 'en-US',
      defaultCurrencyCode = 'USD',
      currencyCode = defaultCurrencyCode,
      disabled,
      hiddenLabel,
      error,
      ...rest
    },
    ref: Ref<HTMLInputElement>,
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [displayValue, setDisplayValue] = useState<string>(
      numberToCurrencyFormat(
        value,
        locale,
        currencyCode,
        maximumFractionDigits,
        useGrouping,
      ),
    );

    useEffect(() => {
      setDisplayValue(
        numberToCurrencyFormat(
          value,
          locale,
          currencyCode,
          maximumFractionDigits,
          useGrouping,
        ),
      );
    }, [value, locale, currencyCode]);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      const numValue = stringToCurrencyNumber(displayValue, locale);
      const newDisplayValue = String(numValue ?? '');
      setDisplayValue(newDisplayValue);
      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      const numValue = stringToCurrencyNumber(displayValue, locale);
      const newDisplayValue = numberToCurrencyFormat(
        numValue,
        locale,
        currencyCode,
        maximumFractionDigits,
        useGrouping,
      );
      setDisplayValue(newDisplayValue);
      onBlur?.(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const strValue = e.target.value;
      setDisplayValue(strValue);

      const numValue = stringToCurrencyNumber(
        numberToCurrencyFormat(
          stringToCurrencyNumber(strValue, locale),
          locale,
          currencyCode,
          maximumFractionDigits,
          useGrouping,
        ),
        locale,
      );
      onChange?.(numValue);
    };

    const handleClearClick = () => {
      if (!inputRef.current) {
        return;
      }
      setNativeValue(inputRef.current, '');
      inputRef.current.dispatchEvent(new Event('input', { bubbles: true }));
      setTimeout(() => inputRef.current?.focus(), 0);
    };

    const hasValue = displayValue !== '';

    return (
      <div
        data-testid="currencybox"
        className={cn('lex-currencybox', className)}
        style={style}
      >
        <div>
          <div>
            {hiddenLabel && (
              <label className="screen-reader" htmlFor={id}>
                {hiddenLabel}
              </label>
            )}
            <input
              id={id}
              data-testid="currencybox-input"
              className={cn(
                'lex-currencybox__input',
                variant && `lex-currencybox__input--${variant}`,
                error && 'lex-currencybox__input--error',
                disabled && 'lex-currencybox__input--disabled',
                inputClassName,
              )}
              ref={(node) => {
                inputRef.current = node;
                if (typeof ref === 'function') {
                  ref(node);
                } else if (ref) {
                  (ref as MutableRefObject<HTMLDivElement | null>).current =
                    node;
                }
              }}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              value={displayValue}
              disabled={disabled}
              style={style}
              {...rest}
            />
            {!disabled && hasValue && (
              <button
                data-testid="currencybox-button"
                className={cn('lex-currencybox__clear', buttonClassName)}
                type="button"
                onClick={handleClearClick}
              >
                <Image src={getIconUrl('clear')} />
              </button>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default CurrencyBox;

export const CurrencyBoxFormField = withFormField(CurrencyBox);
