import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import AntRadioGroup from 'antd/lib/radio/group';

import {
  RadioGroupProps,
  RadioOptionType,
  RadioValueType,
} from './RadioGroup.types';
import { RadioChangeEvent } from '../Radio/Radio.types';
import { Radio } from '../Radio';
import { withFormField } from '../../hocs/withFormField';

import './RadioGroup.scss';

export const RadioGroup: React.FC<RadioGroupProps> = ({
  id,
  style,
  className,
  groupClassName,
  radioClassName,
  variant = 'primary',
  disabled,
  block = false,
  options = [],
  value = undefined,
  defaultValue = undefined,
  hiddenLabel,
  error,
  onChange,
  ...rest
}) => {
  const values = value !== undefined ? value : defaultValue;

  const [checkedValue, setCheckedValue] = useState<RadioValueType>(values);

  useEffect(() => {
    setCheckedValue(values);
  }, [values]);

  const handleChange = (e: RadioChangeEvent) => {
    setCheckedValue(e.target.value);
    onChange?.(e);
  };

  const radios = options.map((option) => {
    const { value, label } = option as RadioOptionType;
    return (
      <div key={`${value}`}>
        <Radio
          id={`${id}-${value}`}
          data-testid="radio-group-radio"
          className={cn('lex-radio-group__radio', radioClassName)}
          value={value}
          variant={variant}
          disabled={disabled}
          error={error}
        >
          {label}
        </Radio>
      </div>
    );
  });

  return (
    <div
      data-testid="radio-group"
      className={cn('lex-radio-group', className)}
      style={style}
    >
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntRadioGroup
        id={id}
        data-testid="radio-group-group"
        className={cn(
          'lex-radio-group__group',
          block && 'lex-radio-group__group--block',
          error && 'lex-radio-group__group--error',
          disabled && 'lex-radio-group__group--disabled',
          groupClassName,
        )}
        value={checkedValue}
        disabled={disabled}
        onChange={handleChange}
        {...rest}
      >
        {radios}
      </AntRadioGroup>
    </div>
  );
};

export default RadioGroup;

export const RadioGroupFormField = withFormField(RadioGroup);
