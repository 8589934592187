import React from 'react';
import cn from 'classnames';
import { Switch as AntSwitch } from 'antd';

import { SwitchProps } from './Switch.types';

import './Switch.scss';

export const Switch: React.FC<SwitchProps> = ({
  style,
  className,
  variant = 'primary',
  disabled,
  ...rest
}) => {
  return (
    <div
      data-testid="switch"
      className={cn(
        'lex-switch',
        variant && `lex-switch--${variant}`,
        disabled && 'lex-switch--disabled',
        className,
      )}
      style={style}
    >
      <AntSwitch data-testid="switch-toggle" disabled={disabled} {...rest} />
    </div>
  );
};

export default Switch;
