import React, { useRef } from 'react';
import cn from 'classnames';

import { ImageButtonProps } from './ImageButton.types';
import Image from '../Image';

import './ImageButton.scss';

const classPrefix = 'lex-image-button';

export const ImageButton: React.FC<ImageButtonProps> = ({
  style,
  activeStyle,
  imageStyle,
  imageActiveStyle,
  labelStyle,
  labelActiveStyle,
  className,
  activeClassName,
  imageClassName,
  imageActiveClassName,
  labelClassName,
  labelActiveClassName,
  active,
  imageAlign = 'left',
  imageSrc,
  imageTitle,
  imageAlt,
  label,
  onClick,
  disableActiveClick,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const noClick = active && disableActiveClick;

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ') {
      ref.current?.click();
    }
  };

  const imageComp = imageSrc && (
    <Image
      className={cn(
        `${classPrefix}__image`,
        imageClassName,
        active && imageActiveClassName,
      )}
      style={active ? { ...imageStyle, ...imageActiveStyle } : imageStyle}
      src={imageSrc}
      title={imageTitle || label}
      alt={imageAlt}
    />
  );

  const labelComp = label && (
    <span
      data-testid="image-button-label"
      className={cn(
        `${classPrefix}__label`,
        labelClassName,
        active && labelActiveClassName,
      )}
      style={active ? { ...labelStyle, ...labelActiveStyle } : labelStyle}
    >
      {label}
    </span>
  );

  return (
    <div
      data-testid="image-button"
      role="button"
      tabIndex={0}
      className={cn(
        classPrefix,
        active && `${classPrefix}--active`,
        noClick && `${classPrefix}--no-click`,
        className,
        active && activeClassName,
      )}
      style={active ? { ...style, ...activeStyle } : style}
      onClick={noClick ? undefined : onClick}
      onKeyUp={noClick ? undefined : handleKeyUp}
      ref={ref}
    >
      {imageAlign === 'left' && imageComp}
      {labelComp}
      {imageAlign === 'right' && imageComp}
    </div>
  );
};

export default ImageButton;
