import React, { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { SearchButtonProps } from './SearchButton.types';
import { Image } from '../Image';
import { getIconUrl, setNativeValue } from '../../utils';

import './SearchButton.scss';

const classPrefix = 'lex-search-button';
const defSearchIconSrc = getIconUrl('search');
const defClearIconSrc = getIconUrl('clear');

export const SearchButton: React.FC<SearchButtonProps> = ({
  id,
  style,
  containerStyle,
  inputStyle,
  className,
  containerClassName,
  inputClassName,
  searchIconSrc,
  searchLabel = 'Search',
  clearIconSrc,
  clearLabel = 'Clear',
  placeholder = 'Type to search',
  value = '',
  onChange,
}) => {
  if (!searchIconSrc) searchIconSrc = defSearchIconSrc;
  if (!clearIconSrc) clearIconSrc = defClearIconSrc;

  const [expand, setExpand] = useState(!!value);
  const [inputValue, setInputValue] = useState(value);
  const containerDiv = useRef<HTMLDivElement>(null);
  const clearButton = useRef<HTMLButtonElement>(null);
  const textInput = useRef<HTMLInputElement>(null);

  const handleBodyClick = useCallback(
    (e: MouseEvent) => {
      if (
        e.target instanceof Node &&
        clearButton.current?.contains(e.target) &&
        !inputValue
      ) {
        setExpand(false);
        return;
      }

      if (
        e.target instanceof Node &&
        containerDiv.current?.contains(e.target)
      ) {
        return;
      }

      setExpand(!!inputValue);
    },
    [inputValue],
  );

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [handleBodyClick]);

  const handleSearchClick = () => {
    if (expand || inputValue || !textInput.current) {
      return;
    }
    setExpand(true);
    textInput.current.focus();
  };

  const handleSearchKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!expand) {
      return;
    }
    const newInputValue = e.target.value;
    onChange?.(newInputValue);
    setInputValue(newInputValue);
  };

  const handleClearClick = () => {
    if (!expand || !textInput.current) {
      return;
    }
    setNativeValue(textInput.current, '');
    textInput.current.dispatchEvent(new Event('input', { bubbles: true }));
    setTimeout(() => textInput.current?.focus(), 0);
  };

  return (
    <div
      id={id}
      style={style}
      ref={containerDiv}
      data-testid="search-button"
      role="button"
      tabIndex={expand ? undefined : 0}
      aria-pressed={expand}
      aria-expanded={expand}
      className={cn(
        classPrefix,
        expand && `${classPrefix}--expanded`,
        className,
      )}
      onClick={handleSearchClick}
      onKeyUp={handleSearchKeyUp}
    >
      <div
        className={cn(
          `${classPrefix}__container`,
          expand && `${classPrefix}__container--expanded`,
          containerClassName,
        )}
        style={containerStyle}
      >
        <Image
          className={cn(`${classPrefix}__search-icon`)}
          aria-label="Search"
          src={searchIconSrc}
          alt={searchLabel}
        />
        <input
          id={id}
          ref={textInput}
          type="text"
          data-testid="search-button-input"
          className={cn(
            `${classPrefix}__input`,
            expand && `${classPrefix}__input--expanded`,
            inputClassName,
          )}
          style={inputStyle}
          placeholder={placeholder}
          readOnly={!expand}
          onChange={handleInputChange}
          value={inputValue}
        />
        <button
          ref={clearButton}
          data-testid="search-button-clear"
          tabIndex={!expand ? undefined : 0}
          aria-label="Clear"
          className={cn(
            `${classPrefix}__clear-btn`,
            expand && `${classPrefix}__clear-btn--expanded`,
          )}
          type="button"
          onClick={handleClearClick}
        >
          <Image src={clearIconSrc} alt={clearLabel} />
        </button>
        <label
          htmlFor={id}
          data-testid="search-button-label"
          className={cn(
            `${classPrefix}__label`,
            !expand && `${classPrefix}__label--expanded`,
          )}
        >
          {searchLabel}
        </label>
      </div>
    </div>
  );
};

export default SearchButton;
