import React from 'react';
import cn from 'classnames';
import AntCheckbox from 'antd/lib/checkbox';

import { CheckboxProps } from './Checkbox.types';

import './Checkbox.scss';

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  className,
  variant = 'primary',
  disabled,
  error,
  ...rest
}) => {
  return (
    <AntCheckbox
      id={id}
      data-testid="checkbox"
      className={cn(
        'lex-checkbox',
        variant && `lex-checkbox--${variant}`,
        error && 'lex-checkbox--error',
        disabled && 'lex-checkbox--disabled',
        className,
      )}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Checkbox;
