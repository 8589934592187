export enum Breakpoint {
  /**
   * X-Small devices (portrait phones)
   */
  XS = '0',
  /**
   * Small devices (landscape phones) - 576px
   */
  SM = '36em',
  /**
   * Medium devices (tablets) - 768px
   */
  MD = '48em',
  /**
   * Large devices (desktops) - 992px
   */
  LG = '62em',
  /**
   * X-Large devices (large desktops) - 1200px
   */
  XL = '75em',
  /**
   * XX-Large devices (larger desktops) - 1400px
   */
  XXL = '87.5em',
}

export enum BreakpointMax {
  /**
   * X-Small devices (portrait phones)
   */
  XS = '35.99875em',
  /**
   * Small devices (landscape phones)
   */
  SM = '47.99875em',
  /**
   * Medium devices (tablets)
   */
  MD = '61.99875em',
  /**
   * Large devices (desktops)
   */
  LG = '74.99875em',
  /**
   * X-Large devices (large desktops)
   */
  XL = '87.49875em',
}
