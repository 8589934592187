import React from 'react';
import cn from 'classnames';
import AntdPopconfirm from 'antd/lib/popconfirm';

import { PopconfirmProps } from './Popconfirm.types';

import './Popconfirm.scss';

export const Popconfirm: React.FC<PopconfirmProps> = ({
  className,
  titleClassName,
  icon,
  title,
  ...rest
}) => {
  return (
    <AntdPopconfirm
      {...rest}
      data-testid="popconfirm"
      overlayClassName={cn('lex-popconfirm', className)}
      destroyTooltipOnHide
      icon={null}
      title={
        <div className={cn('lex-popconfirm__title', titleClassName)}>
          {icon && (
            <div className={cn('lex-popconfirm__title-icon')}>{icon}</div>
          )}
          <div className={cn('lex-popconfirm__title-message')}>{title}</div>
        </div>
      }
    />
  );
};

export default Popconfirm;
