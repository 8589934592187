import React, { useEffect, useState, useRef } from 'react';
import cn from 'classnames';

import { SearchBoxProps, SearchBoxValue } from './SearchBox.types';
import { Image } from '../Image';
import { getIconUrl, setNativeValue } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './SearchBox.scss';

export const SearchBox: React.FC<SearchBoxProps> = ({
  id,
  style,
  className,
  inputClassName,
  buttonClassName,
  variant,
  onChange,
  value,
  disabled,
  hiddenLabel,
  error,
  ...rest
}) => {
  const [searchBoxValue, setSearchBoxValue] = useState<SearchBoxValue>(value);
  const textInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchBoxValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchBoxValue(e.target.value);
    onChange?.(e);
  };

  const handleClearClick = () => {
    if (!textInput.current) {
      return;
    }
    setNativeValue(textInput.current, '');
    textInput.current.dispatchEvent(new Event('input', { bubbles: true }));
    setTimeout(() => textInput.current?.focus(), 0);
  };

  return (
    <div
      data-testid="searchbox"
      className={cn('lex-searchbox', className)}
      style={style}
    >
      <div>
        <Image
          data-testid="searchbox-image"
          className={cn('lex-searchbox__search')}
          src={getIconUrl('search')}
        />
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
        <input
          id={id}
          data-testid="searchbox-input"
          className={cn(
            'lex-searchbox__input',
            variant && `lex-searchbox__input--${variant}`,
            error && 'lex-searchbox__input--error',
            disabled && 'lex-searchbox__input--disabled',
            inputClassName,
          )}
          ref={textInput}
          onChange={handleChange}
          value={searchBoxValue || ''}
          disabled={disabled}
          {...rest}
        />
        {!disabled && searchBoxValue && (
          <button
            data-testid="searchbox-button"
            className={cn('lex-searchbox__clear', buttonClassName)}
            type="button"
            onClick={handleClearClick}
          >
            <Image src={getIconUrl('clear')} />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchBox;

export const SearchBoxFormField = withFormField(SearchBox);
