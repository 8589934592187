import React from 'react';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import { RangeValue } from 'rc-picker/lib/interface';
import generateRangePicker from 'antd/lib/date-picker/generatePicker/generateRangePicker';

import { DateRangePickerProps } from './DateRangePicker.types';
import Image from '../Image';
import { getIconUrl } from '../../utils';
import { withFormField } from '../../hocs/withFormField';

import './DateRangePicker.scss';

const AntRangePicker = generateRangePicker<Dayjs>(dayjsGenerateConfig);

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  id,
  className,
  variant,
  disabled,
  placeholder,
  format = 'MMM D, YYYY',
  defaultValue,
  value,
  hiddenLabel,
  error,
  onChange,
  ...rest
}) => {
  const getValues = (values?: RangeValue<string | Dayjs>) => {
    if (!values) return values;
    return [
      values[0] ? dayjs(values[0]) : null,
      values[1] ? dayjs(values[1]) : null,
    ] as RangeValue<Dayjs>;
  };

  return (
    <>
      {hiddenLabel && (
        <label className="screen-reader" htmlFor={id}>
          {hiddenLabel}
        </label>
      )}
      <AntRangePicker
        id={id}
        data-testid="date-range-picker"
        className={cn(
          'lex-date-range-picker',
          variant && `lex-date-range-picker--${variant}`,
          error && 'lex-date-range-picker--error',
          disabled && 'lex-date-range-picker--disabled',
          className,
        )}
        getPopupContainer={(triggerNode) =>
          triggerNode.parentElement ?? triggerNode
        }
        disabled={disabled}
        placeholder={placeholder ?? ['', '']}
        format={format}
        defaultValue={getValues(defaultValue)}
        value={getValues(value)}
        separator={
          <Image
            className={cn('lex-date-range-picker__separator-icon')}
            src={getIconUrl('arrow_right_alt')}
          />
        }
        suffixIcon={
          <Image
            className={cn('lex-date-range-picker__picker-icon')}
            src={getIconUrl('calendar_today')}
          />
        }
        clearIcon={
          <Image
            className={cn('lex-date-range-picker__clear-icon')}
            src={getIconUrl('clear')}
          />
        }
        onChange={(value, dateRangeStr) => {
          if (!value) {
            onChange?.(dateRangeStr, value);
            return;
          }
          const isoValue = value.map(
            (v) => dayjs(v).toISOString().split('T')[0],
          );
          onChange?.(isoValue, value);
        }}
        {...rest}
      />
    </>
  );
};

export default DateRangePicker;

export const DateRangePickerFormField = withFormField(DateRangePicker);
