export const CDN_URL = 'https://cdn.horizon.lextegrity.com';

export const ASSETS_PATH = '/assets';
export const FAVICONS_PATH = '/assets/favicons';
export const FONTS_PATH = '/assets/fonts';
export const ICONS_PATH = '/assets/icons';
export const GATEWAY_ICONS_PATH = '/assets/icons/gateway';
export const MATERIAL_ICONS_PATH = '/assets/icons/material';
export const SHUTTERSTOCK_ICONS_PATH = '/assets/icons/shutterstock';
export const LOGOS_PATH = '/assets/logos';
