export const numberToCurrencyFormat = (
  numValue: number | undefined,
  locale = 'en-US',
  currencyCode = 'USD',
  maximumFractionDigits = 2,
  useGrouping = true,
): string => {
  if (numValue === undefined || isNaN(numValue)) {
    return '';
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay: 'symbol',
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  });

  return formatter.format(numValue);
};

export const numberToDecimalFormat = (
  numValue: number | undefined,
  locale = 'en-US',
  maximumFractionDigits?: number,
  useGrouping = false,
): string => {
  if (numValue === undefined || isNaN(numValue)) {
    return '';
  }

  const fracDigits = maximumFractionDigits ?? 6;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: maximumFractionDigits,
    maximumFractionDigits: fracDigits,
    useGrouping,
  });

  return formatter.format(numValue);
};

export const stringToCurrencyNumber = (
  strValue: string | undefined,
  locale = 'en-US',
): number | undefined => {
  const numValue = stringToDecimalNumber(strValue, locale);

  if (numValue === undefined) {
    return undefined;
  }

  const rounded = Math.round((numValue + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const stringToDecimalNumber = (
  strValue: string | undefined,
  locale = 'en-US',
): number | undefined => {
  try {
    if (strValue === undefined || !strValue.length) {
      return undefined;
    }

    // detecting and parsing between comma and dot
    const grouping = new Intl.NumberFormat(locale)
      .format(1111)
      .replace(/1/g, '');
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');

    let reversedVal = strValue.replace(new RegExp(`\\${grouping}`, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');

    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');

    if (!reversedVal.length) {
      return undefined;
    }

    const numValue = Number(reversedVal);

    if (isNaN(numValue)) {
      return undefined;
    }

    return numValue;
  } catch (error) {
    return undefined;
  }
};
